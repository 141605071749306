@import "./bootstrap.scss";

@font-face {
    font-family: "Inter";
    src: url("/_CDN/default/assets/common/fonts/inter/Inter-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("/_CDN/default/assets/common/fonts/inter/Inter-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("/_CDN/default/assets/common/fonts/inter/Inter-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

#igre-salebuttons,
#igre-refibuttons {
    justify-content: flex-end;
    padding: 16px 8px;
    margin-bottom: 10px;
}

.igre-loader-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
    background-color: rgba(30, 76, 130, 0.64);
    border-radius: .7rem;
}

.igre-loader-backdrop .igre-loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 40px;
    height: 40px;
    margin: -50px 0 0 -50px;
    border-radius: 50%;
    border-top: 2px solid #ffd963;
    border-right: 2px solid transparent;
    -webkit-animation: loader-animation 700ms linear infinite;
    animation: loader-animation 700ms linear infinite;

}

.igre-loader-text {
    font-family: Inter, sans-serif;
    color: #fff;
    padding-top: 20px;
    padding-left: 60px;
    font-size: 14px;
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    margin: -50px 0 0 -50px;
}

@keyframes loader-animation {
    to {
        transform: rotate(360deg);
    }
}

/* Animation */
.igre-rotate {
    -webkit-animation: loading 2s linear infinite;
    animation: loading 2s linear infinite;
}

@-webkit-keyframes loading {
    0% {
        transform: igre-rotate(0);
    }

    100% {
        transform: igre-rotate(360deg);
    }
}

@keyframes loading {
    0% {
        transform: igre-rotate(0);
    }

    100% {
        transform: igre-rotate(360deg);
    }
}


/* Style the igre-tab */
.igre-calc-tab {
    justify-content: flex-start;
    border: none;
    max-width: 750px;
    margin: 0 0 20px;
    padding: 10px 0px 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.igre-calc-tab li:first-child {
    padding-left: 0;
    border-right: #3b6291 1px solid;
}

.igre-calc-tab li {
    padding: 0 10px;
}

.igre-calc-tab .nav-item {
    display: block;
    width: auto;
    position: relative;
}

.igre-calc-tab .nav-item .nav-link {
    display: block;
    padding: .75rem 0rem;
    border-color: transparent;
    border-bottom: 4px solid transparent;
    color: #ffffff80;
    background: transparent;
    font-size: 14px;
}

.igre-calc-tab .nav-item .nav-link:hover {
    border-bottom-color: #fed863;
}

.igre-calc-tab .nav-item .nav-link.active {
    color: #fff;
    border-bottom-color: #fed863;
}

.igre-tabcontent {
    padding: 6px;
}


/* The Modal */
#igre-emailmodal::before,
#igre-mobilemodal::before {
    content: "";
    position: absolute;
    background: rgba(30, 76, 130, .65);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.7rem;
}

.igre-modal {
    display: none;
    position: absolute;
    z-index: 1050;
    left: 0;
    top: 0;
    margin: 0px;
    width: 100%;
    height: 100%;
    overflow: visible;
    outline: 0;
}

/* Modal Content */
.igre-modal-dialog {
    position: relative;
    width: auto;
    margin: 0rem auto;
    pointer-events: none;
    max-width: 500px;
    z-index: 1000;
}

.igre-modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: auto;
    width: 100%;
    height: auto;
    font-size: 14px;
    background-color: #fff !important;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    color: #3b6291;
    max-width: 500px;
    pointer-events: auto;


    .igre-modal-header {
        padding: 15px 30px 15px 35px;
        border-top-left-radius: .3rem;
        border-top-right-radius: .3rem;
        border-bottom: 0px none;
        background: #f4f9ff;
    }

    .igre-modal-header h4 {
        margin: 0;
        font-size: 20px;
        font-family: Avenir, Arial, sans-serif;
        color: #323840;
        float: left;
    }

    .igre-modal-body {
        position: relative;
        height: auto;
        padding: 35px 35px 10px;
        overflow: visible;
        margin: 0;
        min-height: auto;
        max-height: 100%;

    }

    .igre-modal-footer {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: end;
        justify-content: flex-end;
        border-top: 0px solid #dee2e6;
        border-bottom-right-radius: .3rem;
        border-bottom-left-radius: .3rem;
        padding: 16px 37px 30px;
        background-color: #fff;
    }

    .igre-modal-form-element-label {
        position: absolute;
        left: 50px;
        background: #fff;
        line-height: 20px;
        pointer-events: none;
        font-size: 12px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        transition: all .15s linear;
        z-index: 0;
    }

    .igre-errorlabel {
        color: red;
        width: 100%;
    }

    #igre-emailsubject {
        background-color: #cccccc4d;
    }

    #igre-emailnote {
        resize: none;
    }

    .igre-modal-input-select {
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
    }

    .igre-modal-input-text {
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
    }

    .igre-modal-input-submit {
        width: 100%;
        background-color: #4CAF50;
        color: white;
        padding: 14px 20px;
        margin: 8px 0;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    .igre-modal-input-submit:hover {
        background-color: #45a049;
    }

    @media (max-width: 580px) {

        #igre-emailcancel,
        #igre-emailsend,
        #igre-mobilecancel,
        #igre-mobilesend {
            min-width: 25%;
            margin: 0 6px;
        }

        #igre-mobilelabel {
            font-size: 9px;
        }
    }


}




/* The Close Button */
.igre-close,
.igre-textclose {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    width: 24px;
    height: 24px;
    margin: -8px;
}

.igre-close:hover,
.igre-close:focus,
.igre-textclose:hover,
.igre-textclose:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}


.igre-nodisplay {
    display: none;
}

.igre-calculator {

    font-family: Inter, sans-serif;
    color: #ffffff;
    background: #1e4c82;
    font-size: 14px;
    border-radius: 0.7rem;
    padding: 24px;
    width: 100%;
    max-width: 710px;
    position: relative;

    h4 {
        font-size: 20px;
        color: #ffffff;
        margin-bottom: .5rem;
        margin-top: 0px;
        font-weight: 500;
        line-height: 1.2;
    }

    .modal-body .form-element .form-element-field {
        background: #386091;
        border-color: #386091;
        padding: 20px 12px;
        color: #ffffff;
    }

    .modal-body .form-brk .form-element .form-element-label,
    .modal-body .form-element .form-element-label {
        background: transparent;
        color: #fff;
        top: 10px
    }

    .modal-body .form-select .form-element-field {
        color: #fff;
        padding: 12px;
        box-sizing: border-box;
        height: auto;
        margin: 0 0 12px;
    }


    .modal-body .form-brk .form-element:after {
        color: #fff;
        top: 14px;
        pointer-events: none
    }

    .form-element {
        margin: 0 0 12px;
    }

    .form-element-field {
        outline: none;
        display: block;
        background: #386091;
        padding: 12px;
        color: #fff;
        font-size: 14px;
        line-height: 16px;
        width: 100%;
        height: 42px;
        box-shadow: none;
        border: 1px solid #386091;
        border-radius: 4px;

    }

    .form-element-label {
        position: absolute;
        top: -10px;
        left: 12px;
        background: transparent;
        line-height: 20px;
        pointer-events: none;
        font-size: 12px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        color: #fff;
        transition: all .15s linear;
        z-index: 0;
    }

    .form-checkbox input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }

    .form-checkbox label {
        position: relative;
        cursor: pointer;
        font-size: 12px;
        margin-bottom: 0;
        margin-right: 5px;
    }


    .form-checkbox label::before {
        content: "";
        background-color: transparent;
        border: 2px solid #c0c8d1;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 8px;
        border-radius: 2px;
        height: 16px;
        width: 16px;
    }

    .form-checkbox input:checked+label::before {
        background-color: #f4f7fa;
    }

    .form-checkbox input:checked+label::after {
        content: "";
        display: block;
        position: absolute;
        top: 5px;
        left: 6px;
        width: 5px;
        height: 9px;
        border: solid #0079bf;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }


    .btn {
        margin: 0 6px;
        background: #e6f1ff;
        color: #1e4c82;
        font-size: 14px;
        font-weight: 600;
        font-family: Inter, sans-serif;
        border: none;
        border-radius: 20px;
        cursor: pointer;
    }

    .btn:hover {
        background: #fff;
        color: #1e4c82;
    }

    .btn.btn-primary {
        padding: 0 20px;
        line-height: 40px;
        height: 40px
    }

    .igre-alert-error {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        border-radius: 10px;
        min-width: 60%;
        background-color: #a61e14;
        color: white;
    }

    .igre-alert-closebtn,
    .igre-alert-success-closebtn {
        margin-left: 15px;
        color: white;
        font-weight: bold;
        float: right;
        font-size: 22px;
        line-height: 20px;
        cursor: pointer;
        transition: 0.3s;
    }

    .igre-alert-closebtn:hover,
    .igre-alert-success-closebtn:hover {
        color: black;
    }

    .igre-alert-success {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        background-color: #4c4c4b;
        color: rgb(205, 184, 184);
        border-radius: 10px;
        min-width: 45%;
    }

    @media (max-width: 580px) {
        .btn.btn-primary {
            min-width: 100%;
            margin: 5px 0;
        }

        .igre-errorlabel {
            color: red;
            padding: 15px;
            width: 100%;
        }

        .igre-alert-error {
            position: absolute;
            top: 0;
            right: 0;
            padding: 10px;
            border-radius: 10px;
            width: 100%;
            background-color: #a61e14;
            color: white;
        }

        .igre-alert-success {
            position: absolute;
            top: 0;
            right: 0;
            padding: 10px;
            background-color: #363636;
            color: #ffff;
            border-radius: 10px;
            width: 100%
        }
    }

}

//required is mandatory in input tag, for below css to work
input:focus~.floating-label //,input:not(:focus):valid ~ .floating-label 

//input:not(:placeholder-shown)
//input:placeholder-shown
    {
    //top: 8px;
    bottom: 20px;
    left: 20px;
    font-size: 11px;
    opacity: 1;
}

.igre-inputText {
    font-size: 14px;
    width: -webkit-fill-available;
    // width: 467px;
    height: 35px;
}

.floating-label {
    position: relative;
    pointer-events: none;
    left: 20px;
    bottom: 31px;
    transition: 0.2s ease all;
}

a.igre-cancel-mobile-number,
a.igre-cancel-emailto {
    position: relative;
    top: 20px;
    background: #0044e9;
    color: #fff !important;
    border: none;
    -webkit-tap-highlight-color: transparent;
    border-radius: 25px;
    font-size: 11px;
    padding: 3px 9px;
}

.igre-cancel-emailto,
.igre-cancel-mobile-number {
    svg {
        height: 12px;
        width: 12px;
        vertical-align: bottom;
        background-color: white;
        border-radius: 56%;
        padding-left: 1px;
        margin-left: 3px;
        margin-bottom: 2px;
        font-size: 14px;
    }
}

span.igre-mobile-number-ids {
    display: inline-block;
}

.input-fields {
    margin-bottom: 35px;
    width: 100%;
}